import { useI18n } from 'vue-i18n';

import { LanguageCode } from '@/enums/language-code.enum';

// Import all images from different locales
import * as enImages from './en';
import * as koImages from './ko';
// Import more languages as needed

// Define a type for valid image keys
export type ImageKey =
  | 'ImageVideo1'
  | 'ImageVideo2'
  | 'ImageVideo3'
  | 'ImageVideo4'
  | 'ImageVideo5'
  | 'ImageVideo6'
  | 'ImageVideo7'
  | 'ImageVideo8'
  | 'ImageVideo9';

/**
 * Get localized image based on current locale
 * @param imageName The name of the image to retrieve
 * @returns The localized image URL
 */
export const useLocalizedImage = (imageName: ImageKey): string => {
  const { locale } = useI18n();

  // Select the appropriate image set based on current locale
  let imageSet: Record<ImageKey, string>;

  switch (locale.value) {
    case LanguageCode.Ko:
      imageSet = koImages as Record<ImageKey, string>;
      break;
    // Add more cases as needed
    case LanguageCode.En:
    default:
      imageSet = enImages as Record<ImageKey, string>;
      break;
  }

  // Return the requested image or fallback to English
  return imageSet[imageName] || (enImages as Record<ImageKey, string>)[imageName];
};
